import React, { FC, useState, useEffect, Fragment, ChangeEvent } from "react"

import FaceIcon from "@mui/icons-material/Face"
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import WcIcon from "@mui/icons-material/Wc"
import { Card } from "@mui/material"

import { Select, Typography, Button } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import ButtonSwitch from "../../../components/Buttons/ButtonSwitch"
import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import AvgCountBarGraph from "../../../components/graphs/avgCountBarGraph"
import { BarGraphData } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateSumPerTime, convertToAverageDailyBarChartLabels } from "../../../utils/counterUtils"
import { reformatDwellingBarGraphCounts } from "../../../utils/dwellingUtils"
import { reformatAvgLogs } from "../../../utils/shopUtils"

import styles from "./AvgCountWeek.module.scss"

type CounterLogs = definitions["CounterLogs"]
type ShopHourlyAVGCounts = definitions["HourlyAVGCounts"]
type CategoryDailyLogs = definitions["LogsPerDay"]
type DwellingAreaHourlyAVGCounts = definitions["DwellingAreaHourlyAVGCounts"]
type DwellingAreaWeekDaysCounts = definitions["DwellingAreaWeekDaysCounts"]

interface Props {
  logsData:
    | CounterLogs[]
    | ShopHourlyAVGCounts[]
    | CategoryDailyLogs[]
    | DwellingAreaHourlyAVGCounts[]
    | DwellingAreaWeekDaysCounts[]
  loading: boolean
  interval: Duration
  reference?: any
  hourlyAvg?: boolean
  isTenants?: boolean
  noCategorySelected?: boolean
  isCarsAnalytics?: boolean
  isDwellingArea?: boolean
}

const AvgCountWeek: FC<Props> = ({
  logsData,
  loading,
  interval,
  reference,
  hourlyAvg,
  isTenants,
  noCategorySelected,
  isCarsAnalytics,
  isDwellingArea,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [graphData, setGraphData] = useState<BarGraphData[]>([
    {
      Day: "",
      "Male Count": 0,
      "Female Count": 0,
      "Adult Count": 0,
      "Children Count": 0,
      "Unknown Age Count": 0,
      "Unknown Gender Count": 0,
    },
  ])
  const [showExport, setShowExport] = useState(false)
  const [dwellType, setDwellType] = useState<"Dwelling counts" | "Dwelling time">("Dwelling counts")

  // formatting the data fetched to match nivo acceptable data form
  useEffect(() => {
    if (!loading && logsData) {
      if (isDwellingArea) {
        setGraphData(
          reformatDwellingBarGraphCounts(
            logsData as DwellingAreaHourlyAVGCounts[] | DwellingAreaWeekDaysCounts[],
            dwellType
          )
        )
      } else if (hourlyAvg) {
        setGraphData(reformatAvgLogs(logsData))
      } else {
        if (isTenants) {
          setGraphData(convertToAverageDailyBarChartLabels(reformatAvgLogs(logsData, true)))
        } else {
          setGraphData(convertToAverageDailyBarChartLabels(calculateSumPerTime(logsData)))
        }
      }
      // hiding export button if there are no data to be shown
      if (logsData.length > 0 && interval && (interval!.days! >= 7 || interval!.months! >= 1)) {
        setShowExport(true)
      } else {
        setShowExport(false)
      }
    } else {
      setShowExport(false)
    }
  }, [loading, logsData, interval, hourlyAvg, isTenants, isDwellingArea, dwellType])
  // export to CSV button
  const handleExportCSV = () => {
    const builder = new CsvBuilder(
      `Average ${isDwellingArea ? dwellType.toLowerCase() : "count"} for ${hourlyAvg ? "hours" : "days"} of week ${
        activeTab === 0 ? "(Gender Distribution)" : "(Age Distribution)"
      }.csv`
    )
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (!loading && graphData)
      graphData.map((row) =>
        csvFormattedData.push(
          activeTab === 0
            ? [
                row.Day ? row.Day : row.Hour ? row.Hour : "",
                row["Male Count"].toString(),
                row["Female Count"].toString(),
                row["Unknown Gender Count"]?.toString() || "0",
              ]
            : [
                row.Day ? row.Day : row.Hour ? row.Hour : "",
                row["Adult Count"].toString(),
                row["Children Count"].toString(),
                row["Unknown Age Count"]?.toString() || "0",
              ]
        )
      )
    builder
      .setColumns(
        activeTab === 0
          ? [`${hourlyAvg ? "Hour" : "Day"}`, "Male Count", "Female Count", "Unknown Gender Count"]
          : ["Day", "Adult Count", "Children Count", "Unknown Age Count"]
      )
      .addRows(csvFormattedData)
      .exportFile()
  }

  const handleDataTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDwellType(event.target.value as "Dwelling counts" | "Dwelling time")
  }

  const dwellingOptions = ["Dwelling counts", "Dwelling time"]

  return (
    <Fragment>
      <Card
        className={styles.cardWrapper}
        ref={reference}
        sx={{
          width: isCarsAnalytics ? "50%" : "100%",
        }}
      >
        <div className={styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={styles.titleAndSelect}>
              <Typography variant="h2-bold" variantColor={2} noWrap textTransform="lowercase">
                {hourlyAvg ? "Hourly average " : "Week day average "}
                {isDwellingArea && dwellType}
              </Typography>
              {isDwellingArea && (
                <div className={styles.dataSelect}>
                  <Select options={dwellingOptions} value={dwellType} handleChange={handleDataTypeChange} />
                </div>
              )}
            </div>
          </div>
          <ButtonSwitch
            activePage={activeTab}
            pages={["Gender", "Age"]}
            handleSelectButton={setActiveTab}
            disabled={noCategorySelected === true || loading === true ? true : false}
            pagesIcons={[<WcIcon />, <FaceIcon />]}
          />
        </div>
        {isTenants && noCategorySelected ? (
          <GraphEmptyState noCategorySelected={true} />
        ) : (
          <>
            <AvgCountBarGraph
              barGraphData={graphData}
              loading={loading}
              interval={interval}
              isGender={activeTab === 0}
              hourlyAvg={hourlyAvg}
              dwellType={isDwellingArea && dwellType}
            />
            {showExport && (
              <div
                style={{
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  disabled={!!noCategorySelected}
                  startIcon={<GetAppOutlinedIcon />}
                  onClick={handleExportCSV}
                  className={styles.export}
                >
                  Export
                </Button>
              </div>
            )}
          </>
        )}
      </Card>
    </Fragment>
  )
}
export default AvgCountWeek
