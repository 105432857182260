import { Fragment, useState } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { DateRangePicker, InputOptionValue, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../API/VisionAPI"
import CheckboxesLineGraph from "../../components/GraphCards/CheckboxesLineGraphCard"
import PaginatedBarGraph from "../../components/GraphCards/PaginatedBarGraphCard"
import { useDateQuery } from "../../hooks/useDateQuery"
import { definitions } from "../../types/Generated/apiTypes"
import auth from "../../utils/auth"
import { calculateDateSlice, convertToColoredLineGraphData } from "../../utils/genericHelpers"
import { InputOptionValueArrayParamConfig } from "../../utils/queryParamCustomConfigs"
import Leaderboard from "./components/Leaderboard"

import styles from "./BranchesAnalytics.module.scss"

type BranchData = definitions["BranchesAnalytics"]
type Branch = definitions["Branch"]

// map selected branches into comma separated ids string
const getBranchIdsString = (branches: InputOptionValue[]) => {
  return branches?.length > 0 ? branches.map((branch) => branch.value).join(",") : undefined
}

const BranchesAnalytics = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [selectedBranches, setSelectedBranches] = useQueryParam(
    "branches",
    withDefault(InputOptionValueArrayParamConfig, [])
  )

  const userId = auth.getUserId()
  const timeGrain = calculateDateSlice(startDate, endDate)

  const { data: branches, isLoading: branchesLoading } = useQuery<Branch[]>(
    ["fetchUserBranches", userId],
    ({ queryKey }) => VisionAPI?.fetchUserBranches({ user_id: queryKey[1] as string }),
    {
      enabled: !!userId,
    }
  )

  const { data: branchesData, isLoading: branchesDataLoading } = useQuery<BranchData[]>(
    [
      "fetchUserBranches",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      timeGrain,
      getBranchIdsString(selectedBranches),
    ],
    ({ queryKey }) =>
      VisionAPI?.fetchBranchesAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch_ids: queryKey[4] as string,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  const handleChangeBranches = (selectedBranches: InputOptionValue[]) => {
    setSelectedBranches(selectedBranches)
  }

  // branches mapped to a form SUI Select optionsWithValues accept
  const mappedBranches =
    branches?.map((branch) => ({
      label: branch.name,
      value: branch.id!,
    })) || []

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Comprehensive analytics dashboard showcasing counts and trends for selected branches"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Multiple Branches
      </Typography>
      <div className={styles.filtersWrapper}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
        <InputText
          size={280}
          value={autoCompleteValue}
          selectedValues={selectedBranches}
          handleChange={(event) => setAutoCompleteValue(event.target.value as string)}
          setSelectedValues={handleChangeBranches}
          variant="filled"
          multiple
          label="Filter By Branch"
          isNotchedLabel
          placeholder="search for a branch"
          loading={branchesLoading}
          optionsWithValues={mappedBranches}
          horizontalScroll
          hideDescription
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Branches Counts - Bar graph */}
          <Grid item xs={12}>
            <PaginatedBarGraph
              branchesData={branchesData}
              loading={branchesDataLoading}
              startDate={startDate?.format("YYYY-MM-DD")}
              endDate={endDate?.format("YYYY-MM-DD")}
              isBranchesAnalytics
            />
          </Grid>
          {/* Counts Over Time - Line graph */}
          <Grid item xs={12}>
            <CheckboxesLineGraph
              graphData={convertToColoredLineGraphData({
                data: branchesData,
                indexByKey: "branch_name",
                logsKey: "logs",
                logTimeStampKey: "timestamp",
                logCountKey: "count_in_sum",
              })}
              isLoading={branchesDataLoading}
              title="Counts Over Time"
              timeGrain={timeGrain}
              columnsKey={timeGrain === "day" ? "Date" : "Hour"}
              rowsKey="Branch"
            />
          </Grid>
        </Grid>
        {/* Branches Leader board  */}
        <Grid item xs={12} md={4}>
          <Leaderboard data={branchesData} isLoading={branchesDataLoading} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default BranchesAnalytics
