import { FC, Fragment } from "react"

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import { Card } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"
import moment from "moment"

import { ColoredGraphData } from "../../types/Custom/Types"
import { getDefaultCheckboxes } from "../../utils/genericHelpers"
import CategoriesLineGraph from "../graphs/CheckboxesLineGraph"

import styles from "./CheckboxesLineGraphCard.module.scss"

interface Props {
  graphData: ColoredGraphData[]
  title: string
  columnsKey: string
  rowsKey: string
  isLoading?: Boolean
  timeGrain?: "day" | "hour"
  isLargeInterval?: boolean
  graphHeight?: number
  ref?: React.MutableRefObject<any>
}

const CheckboxesLineGraphCard: FC<Props> = ({
  title,
  graphData,
  isLoading,
  timeGrain,
  isLargeInterval,
  columnsKey,
  rowsKey,
  graphHeight = 350,
  ref,
}) => {
  const defaultCheckboxes = getDefaultCheckboxes(graphData)
  const isEmpty = (graphData && graphData.length < 1) || !graphData

  const handleExportCSV = () => {
    const builder = new CsvBuilder(`${title} table.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    const daysArray: string[] = []
    if (!isLoading && graphData)
      graphData.map((element, graphDataIndex) => {
        const csvRow: string[] = []
        element.data.map((log) => {
          const logHour = moment(log.x).format(timeGrain === "day" ? "MMM D, YYYY" : "HH A")
          graphDataIndex === 0 && daysArray.push(logHour)
          return csvRow.push(log.y.toString())
        })
        return csvFormattedData.push([element.id, ...csvRow])
      })
    const daysWithCountsArray: string[] = []
    graphData.map((element) => daysWithCountsArray.push(element.id))
    builder
      .setColumns([`${rowsKey} \\ ${columnsKey}`, ...daysArray])
      .addRows(csvFormattedData)
      .exportFile()
  }

  return (
    <Card className={styles.wrapper} ref={ref}>
      <Fragment>
        <div className={styles.header}>
          <Typography variant="h2-bold" variantColor={2}>
            {title}
          </Typography>
        </div>

        <CategoriesLineGraph
          data={graphData}
          isLoading={isLoading}
          graphHeight={graphHeight}
          defaultCheckboxes={defaultCheckboxes}
          timeGrain={timeGrain}
          isLargeInterval={isLargeInterval}
        />

        {!isEmpty && !isLoading && (
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            <Button startIcon={<GetAppOutlinedIcon />} onClick={handleExportCSV} className={styles.export}>
              Export
            </Button>
          </div>
        )}
      </Fragment>
    </Card>
  )
}
export default CheckboxesLineGraphCard
